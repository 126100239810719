const debounce = <T extends any[], R>(fn: (...args: T) => R, delay: number) => {
  let timeoutId: NodeJS.Timeout;
  return (...args: T) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      fn(...args);
    }, delay);
  };
};

export default debounce;
