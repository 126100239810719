export enum ERROR_CODES {
  UNAUTHORIZED = "UNAUTHORIZED",
  INTERNAL_SERVER_ERROR = "INTERNAL_SERVER_ERROR",
  BAD_REQUEST = "BAD_REQUEST",
  UNKNOWN = "UNKNOWN",
  ERROR_PARSING_RESPONSE = "ERROR_PARSING_RESPONSE",
}

export class BaseError extends Error {
  errorCode: string;
  statusCode: number;

  constructor(message: string, errorCode: string, statusCode: number) {
    super(message);
    this.name = this.constructor.name;
    this.errorCode = errorCode;
    this.statusCode = statusCode;
  }
}

export class UnauthorizedError extends BaseError {
  constructor(message: string, statusCode = 401) {
    super(message, ERROR_CODES.UNAUTHORIZED, statusCode);
  }
}

export class InternalServerError extends BaseError {
  constructor(message: string, statusCode = 500) {
    super(message, ERROR_CODES.INTERNAL_SERVER_ERROR, statusCode);
  }
}

export class BadRequestError extends BaseError {
  constructor(message: string, statusCode = 400) {
    super(message, ERROR_CODES.BAD_REQUEST, statusCode);
  }
}

export class UnknownError extends BaseError {
  constructor(message: string, statusCode: number) {
    super(message, ERROR_CODES.UNKNOWN, statusCode);
  }
}

export class ErrorParsingResponseError extends BaseError {
  constructor(message: string) {
    super(message, ERROR_CODES.ERROR_PARSING_RESPONSE, 0);
  }
}
