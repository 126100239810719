import { ElementViewed } from "@/hooks/eventing/types/redventures.usertracking.v3.ElementViewed";

/* Most of these enumerated values are from:
   https://docs.google.com/spreadsheets/d/1tGQllKgFI14VhWwgvT1bERKfGc91ngI4PT1zJdBdJmw/edit?gid=85848780#gid=85848780 */

export enum PageNames {
  Homepage = "homepage",
  SchoolAndPartners = "school-and-partners",
  SearchPage = "search-page",
  Career = "career",
  LearnMore = "learn-more",
  Become = "become",
  AccountCreation = "account-creation",
  SignIn = "sign-in",
  Checkout = "checkout",
  ProductPage = "product-page", // not in enum doc
  Other = "other", // Agreed upon ad-hoc
  Default = Other,
}

export enum ElementType {
  Link = "LINK",
  Entry = "ENTRY",
  Button = "BUTTON",
  Filter = "FILTER",
}

export enum FormatType {
  ProductCard = "product-card",
  Search = "search",
  SearchSuggestion = "search-suggestion",
  LongProductCard = "long-product-card",
}

export enum FormatSubType {
  Grid = "grid",
  HorizontallyStacked = "horizontal-stacked",
  VerticallyStacked = "vertical-stacked",
  SearchResult = "search-result",
}

export enum CommonVariants {
  AllProductsMixed = "all-products/mixed",
}

export enum ActionOutcome {
  Click = "click",
  Enroll = "enroll",
  ApplyNow = "apply-now",
  NextStep = "next-step",
  Submit = "submit",
  Previous = "previous",
  GetStarted = "get-started",
}

export const NYI_STRING = "tbd";
export const NYI_NUMBER = 0;

export const NO_BRAND = "brand-not-set";
export const NO_SUBJECT = "not-available";

export const DEFAULT_USE_IN_VIEW_OPTIONS = {
  threshold: 0.5,
  triggerOnce: true,
};

export interface ElementShared {
  // Required
  webElement: NonNullable<ElementViewed["webElement"]>;
}

/**
 * Additional info for Conversion Tracking
 *
 * NOTE: We ignore all other setters for Conversion information.
 */
export interface ConversionTrackingInfo {
  productName: string;
  brand: string;
  category: string;
  variant: string;
}
