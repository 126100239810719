import {
  ConversionTrackingInfo,
  ElementShared,
  NO_SUBJECT,
  PageNames,
} from "@/constants/tagular/main";
import { TagularEventingRequiredProps } from "@/types/Tagular";
import { assert } from "@/utils/functions/assert";
import debounce from "@/utils/debounce";
import { tagular } from "@highereducation/cohesion-utils-react";
import { needsNewPageView } from "@/utils/tagular/needsNewPageView";
import Cookies from "js-cookie";
import { v4 as uuid } from "uuid";
import { Course, ProcessedCourse } from "@/lib/course/types";

declare global {
  interface Window {
    /**
     * Cohesion pageType for the current page. Set this to have the webContext
     * autofilled by tagular along with the page type.
     */
    chsn_pageType?: string;
    /**
     * Used to limit having 2 debouncers running, we do invoke the component
     * that uses the pageTrackingObjectUpdate more than once.
     */
    edx_debouncing_pageView?: boolean;
  }
}

/**
 * Fill the Window page tracking variables to permit Tagular/Cohesion to full in
 * the webContext automatically.
 * @param pageType The page type to be submitted along with the event.
 * @param override Should force the change
 */
export function pageTrackingObjectUpdate(
  pageType: string | undefined,
  override: boolean = false,
): void {
  if (pageType === PageNames.Default && window.chsn_pageType !== undefined)
    return;
  if (!override) {
    assert(
      window.chsn_pageType !== undefined &&
        window.chsn_pageType !== PageNames.Default &&
        window.chsn_pageType !== pageType,
      "You are not permitted to override the page type.",
    );
    return;
  }

  window.chsn_pageType = pageType;

  const sendNewPageView = needsNewPageView();

  if (!window.edx_debouncing_pageView && sendNewPageView) {
    window.edx_debouncing_pageView = true;
    debounce(() => {
      tagular("pageView");
      window.edx_debouncing_pageView = false;
    }, 100)();
  }
}

/**
 * Get the current page type.
 */
export function getPageType(): string | undefined {
  return window.chsn_pageType;
}

/**
 * Determine if Tagular should event on the core page invoking this element.
 */
export function isTagularEnabledForPage(): boolean {
  return getPageType() !== undefined;
}

/**
 * Determines if we have what we need in TagularEventingRequiredProps and asserts
 * that those required values are set.
 *
 * @param props A dict of properties that may or may not contain what we need for eventing
 * @param checkWindowConfig Should we check the window to see if were setup (default: true)
 */
export function isTagularEnabledForElement(
  props: TagularEventingRequiredProps,
  checkWindowConfig = true,
): props is {
  html_id: string;
  location: string;
  conversionTrackingInfo?: ConversionTrackingInfo;
  [key: string]: any;
} {
  const isTagularEnabled = checkWindowConfig ? isTagularEnabledForPage() : true;
  const hasBasicTagularProps =
    typeof props.html_id === "string" && typeof props.location === "string";
  const hasConversionProps = props.conversionTrackingInfo !== undefined;

  return isTagularEnabled && (hasBasicTagularProps || hasConversionProps);
}

/**
 * Convert a ElementShared object to a cloned TagularWebElement
 * @param shared ElementShared instance
 */
export function convertElementSharedToWebElement(
  shared: ElementShared,
): ElementShared["webElement"] {
  return { ...shared.webElement };
}

/**
 * Make Near Slugs from Plain Strings for ease of eventing.
 *
 * @example
 *   "Computer Science" => "computer-science"
 *   "Humanities & Arts" => "humanities-&-arts"
 *   "Someone added a space " => "someone-added-a-space"
 *
 * @param x Input String
 */
export function hyphenateForTagular(x: string): string {
  return x
    .trim()
    .toLowerCase()
    .replace(/[^\w&]/g, "-");
}

/**
 * Parse a subject or list of subjects and return a tagular-safe subject string
 * @param subjects subject or list of subjects
 * @see hyphenateForTagular
 */
export function getTagularSubject(
  subjects: string | string[] | undefined | null,
): string {
  return hyphenateForTagular(
    (subjects instanceof Array ? subjects[0] : subjects) ?? NO_SUBJECT,
  );
}

/**
 * Get a Tagular ready subject from the Course/ProcessedCourse if available.
 * @param course
 */
export function getCourseSubject(course?: ProcessedCourse | Course) {
  const hypenateSubject = (subject: string | undefined) => {
    if (subject) return hyphenateForTagular(subject);
    return subject;
  };
  return (
    course?.subjects?.[0].slug ??
    hypenateSubject(course?.subjectEn) ??
    hypenateSubject(course?.subjectEs)
  );
}

/**
 * Issues a new Correlation ID
 */
export function issueCorrelationID() {
  return uuid();
}

/**
 * Gets the base domain of the site, this is the last 2 sections, the domain and TLD.
 *
 * This will not work on sites with 2 level TLDs like `example.co.uk`.
 */
function getBaseDomain() {
  const hostname = window.location.hostname;
  const parts = hostname.split(".");
  return parts.length > 2 ? parts.slice(-2).join(".") : hostname;
}

/**
 * Fetch a single query parameter
 * @param name Name of the parameter
 */
function getQueryParameter(name: string): string | null {
  const params = new URLSearchParams(window.location.search);

  return params.get(name);
}

const TAGULAR_CORRELATION_COOKIE_NAME = "tglr_correlation_id";

/**
 * Manually set the correlation id
 * @param newId New ID
 */
export function setCorrelationId(newId: string): void {
  const domain = `.${getBaseDomain()}`;
  Cookies.remove(TAGULAR_CORRELATION_COOKIE_NAME, {
    domain: domain,
  });
  Cookies.set(TAGULAR_CORRELATION_COOKIE_NAME, newId, {
    expires: 1 / 48 /* 30 mins */,
    domain: domain,
  });
}

/**
 * Fetch or Create a Tagular CorrelationID. This also refreshes the cookie's expiry.
 *
 * @param reset Reset the cookie id if its present
 */
export function getCorrelationID(reset = false): string {
  const PARAM_NAME = "correlationId";

  let paramId =
    getQueryParameter(PARAM_NAME) ||
    (reset ? null : Cookies.get(TAGULAR_CORRELATION_COOKIE_NAME));

  if (!paramId) {
    paramId = issueCorrelationID();
  }

  setCorrelationId(paramId);

  return paramId;
}

// define and export an eventCacheType enum with values 'MegaNav' and 'ProductLink'
export enum EventCacheType {
  MegaNav = "MegaNav",
  ProductSearch = "ProductSearch",
}

let eventCache: { [key: string]: Record<string, any>[] } = {};

export function resetEventCache(cacheName: EventCacheType): void {
  eventCache[cacheName] = [];
}

export function inEventCache(
  cacheName: EventCacheType,
  item: Record<string, any>,
): boolean {
  // set isCache variable to true is all properties of the item are in a single item in eventCache
  const inCache = eventCache[cacheName].find((cacheItem) =>
    Object.keys(item).every((key) => cacheItem[key] === item[key]),
  );

  if (!inCache) {
    eventCache[cacheName].push(item);
    return false;
  }

  return true;
}

export function getVariantCategoryFromUrl(url: string) {
  // parse url and use just the path
  try {
    const urlObject = new URL(url, window.location.origin);
    url = urlObject.pathname;
  } catch (e) {
    console.log(`Error parsing url: "${url}"`, e);
  }
  // 1 strip /certificates
  let urlWithoutCertificates = url.replace("/certificates", "");
  // trim leading /
  if (urlWithoutCertificates.startsWith("/")) {
    urlWithoutCertificates = urlWithoutCertificates.slice(1);
  }
  // parse url folders
  const urlFolders = urlWithoutCertificates.split("/");
  // 1st lob is variant
  let variant = hyphenateForTagular(urlFolders[0]),
    category = "";
  // if more then 2 folders present, use 2nd for category
  if (urlFolders.length > 2) {
    category = hyphenateForTagular(urlFolders[1]) || "";
  }
  // boot-camps -> bootcamps for variant and learn -> courses for category
  Object.entries({
    "boot-camps": "bootcamps",
    learn: "courses",
  }).forEach(([key, value]) => {
    variant = variant.replace(key, value);
  });
  return { variant, category };
}
