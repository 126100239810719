import pino, { LoggerOptions } from "pino";
import getEnv from "@/utils/getEnv";

const options: LoggerOptions = {
  level: getEnv("LOG_LEVEL", "info"),
};

// if you need to pretty print logs, set the environment variable PRETTY_PRINT_LOGS=true
// you can also pipe the output to pino-pretty
// but for things like running tests, this is easier
if (getEnv("PRETTY_PRINT_LOGS") === "true") {
  options.transport = {
    target: "pino-pretty",
    options: {
      colorize: true,
    },
  };
}

const logger = pino(options);
const parentLogger = logger.child({
  version: process.env.NEXT_PUBLIC_APP_VERSION,
});

export type Logger = typeof logger;
export default parentLogger;
