"use client";
import { DEFAULT_USE_IN_VIEW_OPTIONS } from "@/constants/tagular/main";
import {
  ATTRIBUTES_ELEMENT,
  ATTRIBUTES_PRODUCT,
} from "@/components/CohesionReady/eventing/constants";
import { EventType, EventAttributes } from "./types";
import {
  beamElementClicked,
  beamElementViewed,
  beamIdentify,
  beamProductClicked,
  beamProductViewed,
} from "@/components/CohesionReady/eventing/events";

// Get the event type from the element data attribute
const getEventType = (element: Element): EventType | null => {
  return element.getAttribute("beam-event") as EventType | null;
};

// Get the event data from the element data attributes
const parseEventDataFromAttributes = (
  element: Element,
  attributeList: readonly string[],
): EventAttributes => {
  return attributeList.reduce((acc, attr) => {
    const value = element.getAttribute(attr);
    if (value) {
      acc[attr] = value;
    }
    return acc;
  }, {} as EventAttributes);
};

// Get the event attributes based on element type
const getEventAttributes = (element: Element): EventAttributes => {
  const type = getEventType(element);

  switch (type) {
    case "product":
      return parseEventDataFromAttributes(element, ATTRIBUTES_PRODUCT);
    case "element":
      return parseEventDataFromAttributes(element, ATTRIBUTES_ELEMENT);
    default:
      return {};
  }
};

// Beams the view event based on element type
const observerCallback = (
  entries: IntersectionObserverEntry[],
  observer: IntersectionObserver,
) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      const element = entry.target as Element;
      const type = getEventType(element);
      const attributes = getEventAttributes(element);

      switch (type) {
        case "product":
          beamProductViewed(attributes);
          break;
        case "element":
          beamElementViewed(attributes);
          break;
      }

      observer.unobserve(element);
    }
  });
};

// Beams the click event based on element type
const handleClick = (element: Element) => {
  const type = getEventType(element);
  const attributes = getEventAttributes(element);

  switch (type) {
    case "product":
      beamProductClicked(attributes);
      break;
    case "element":
      beamElementClicked(attributes);
      break;
  }
};

// Setup viewed and clicked events for elements with beam-event attribute
export const handleTagularDataTags = () => {
  const elements = document.querySelectorAll("[beam-event]");

  const observer = new IntersectionObserver(
    observerCallback,
    DEFAULT_USE_IN_VIEW_OPTIONS,
  );

  elements.forEach((element) => {
    observer.observe(element);
    (element as HTMLElement).addEventListener("click", () =>
      handleClick(element),
    );
  });

  return () => {
    observer.disconnect();
  };
};

export const handleTagularIdentify = (
  cohesionAnonymId: string,
  segmentAnonymId: string,
) => {
  const attributes = {
    "cohesion-anonymous-id": cohesionAnonymId,
    "segment-anonymous-id": segmentAnonymId,
  } as EventAttributes;

  beamIdentify(attributes);
};

export const handleSegmentIdentify = (cohesionAnonymId: string) => {
  window.analytics.identify(window.analytics.user().id() as string, {
    cohesion_anonymous_id: cohesionAnonymId,
  });
};
