import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { BadRequestError, InternalServerError, UnknownError } from "./errors";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

function isServerSideCode(): boolean {
  return typeof window === "undefined";
}

/**
 * Should we enable a debug feature for a certain item by using  QP/SessionStorage Debug Parameters
 * @param key
 */
export function shouldDebugWithKey(key: string): boolean {
  // Stolen from: https://github.com/RedVentures/cohesion-client-js/blob/main/src/libs/tagular/debug.js#L3
  if (isServerSideCode()) {
    return false;
  }

  function getSessionStorage(name: string) {
    try {
      return sessionStorage.getItem(name);
    } catch (e) {
      return undefined;
    }
  }

  const url = new URL(window.location.href);
  const param = url.searchParams.get(key);
  const session = getSessionStorage(key);

  if ((param && param === "true") || (!param && session === "true")) {
    sessionStorage.setItem(key, "true");
    return true;
  } else {
    return false;
  }
}

export async function getResponseError(response: Response) {
  let apiMessage = "";
  try {
    const errorData = await response.json();
    // error messages come back in a variety of formats
    // so we need to check for them all and fall back to status text
    apiMessage =
      errorData.detail ||
      errorData.message ||
      errorData.error ||
      response.statusText;
  } catch (e) {
    // If parsing fails, fallback to status text
    apiMessage = response.statusText;
  }
  if (response.status >= 500) {
    return new InternalServerError(
      `Internal server error: ${apiMessage}`,
      response.status,
    );
  }
  if (response.status >= 400) {
    return new BadRequestError(
      `Bad request to partner API: ${apiMessage}`,
      response.status,
    );
  }

  return new UnknownError(
    `Unknown partner api error: ${apiMessage}`,
    response.status,
  );
}
