"use client";
import { cohesion } from "@highereducation/cohesion-utils-react";
import {
  handleSegmentIdentify,
  handleTagularDataTags,
  handleTagularIdentify,
} from "./eventing";
import logger from "@/lib/logger";
import { useEffect } from "react";

const listenForCohesionErrors = () => {
  /**
   * Log an error if the event fails and ship error to New Relic
   */
  cohesion(
    "tagular:eventCompleted",
    (event: { properties?: { "@type"?: string } }, xhr) => {
      if (xhr.status !== 200) {
        const message = "Tagular event failed";
        const details = {
          eventName: event?.properties?.["@type"],
          message: xhr.responseText,
          status: xhr.status,
        };

        logger.error(message, details);
        window.newrelic.noticeError(new Error(message), details);
      }
    },
  );
};

const waitForNewRelic = (callback: () => void) => {
  const timeout = setTimeout(() => {
    if (window.newrelic) {
      callback();
    } else {
      clearTimeout(timeout);
      waitForNewRelic(callback);
    }
  }, 100);
};

let hasSentNewRelicEvents = false;
export const CohesionReady = () => {
  useEffect(() => {
    logger.info("Client Ready");
    const version = process.env.NEXT_PUBLIC_APP_VERSION || "";
    waitForNewRelic(() => {
      window.newrelic.addRelease(version, version);
      window.newrelic.setCustomAttribute("version", version);
    });

    cohesion("ready", function () {
      handleTagularDataTags();
      listenForCohesionErrors();

      window.analytics.ready(function () {
        const cohesionAnonymId = window.tagular?.("getAliasSet")?.[
          "anonymousId"
        ] as string;
        const segmentAnonymId = window.analytics.user().anonymousId();

        handleTagularIdentify(cohesionAnonymId, segmentAnonymId);
        handleSegmentIdentify(cohesionAnonymId);

        waitForNewRelic(() => {
          if (hasSentNewRelicEvents) return;
          hasSentNewRelicEvents = true;
          window.newrelic.setCustomAttribute(
            "cohesionAnonymId",
            cohesionAnonymId,
            true,
          );
          window.newrelic.setCustomAttribute(
            "segmentAnonymId",
            segmentAnonymId,
            true,
          );
          window.newrelic.addPageAction("cohesionReady");
        });
      });
    });
  }, []);

  return null;
};
