import { shouldDebugWithKey } from "@/lib/utils";

class AssertionError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "AssertionError";
    Object.setPrototypeOf(this, AssertionError.prototype);
  }
}

const TAGULAR_DEBUG_KEY = "tagularDebug";

/**
 * A Tagular sensitive Assertion Mechanism, if you have tagularDebug=true in your SessionStorage or AddressBar we will
 * throw an exception so you cant miss it.
 *
 * This has the same functionality of `console.assert`
 *
 * @see console.assert
 * @param condition
 * @param data
 * @throws AssertionError
 */
export function assert(condition: boolean, ...data: any[]): boolean {
  const shouldThrow = shouldDebugWithKey(TAGULAR_DEBUG_KEY);

  const log = console.assert;

  const buildMessage = (data: any[]): string => {
    if (typeof data[0] === "string") {
      const [template, ...substitutions] = data;
      return template.replace(/%[sdifoO]/g, () => substitutions.shift());
    }
    return data.map((item) => JSON.stringify(item)).join(" ");
  };

  if (!condition) {
    if (shouldThrow) {
      const message = data.length > 0 ? buildMessage(data) : "Assertion failed";
      throw new AssertionError(message);
    } else {
      log(condition, ...data);
    }
  }

  return condition;
}
