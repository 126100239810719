import { EnvironmentVariables } from "@/types/Environment";

const hasProcess = typeof process !== "undefined" && "env" in process;

const getEnv = (property: EnvironmentVariables, defaultValue = ""): string => {
  if (!hasProcess) {
    return defaultValue;
  }

  return process.env[property] || defaultValue;
};

export const getEnvOrFail = (property: EnvironmentVariables): string => {
  const envVal = getEnv(property, "");

  if (envVal === "") {
    throw new Error(`Missing required environment variable: ${property}`);
  }

  return envVal;
};

export default getEnv;
