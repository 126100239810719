"use client";

import { useEffect, useRef } from "react";
import { usePathname } from "next/navigation";

const NEED_NEW_PV_STORAGE_KEY = "rv_edx_needs_new_pv";

/**
 * Monitor for page changes and tell the next Tagular PageType setter to update
 * the tagular webXontext by sending tagular('pageView');.
 */
export function useNeedsNewPageViewOnChange() {
  const pathname = usePathname();
  const previousPathname = useRef(pathname); // Track previous pathname

  useEffect(() => {
    // Skip logic on initial load
    if (previousPathname.current !== pathname) {
      sessionStorage.setItem(NEED_NEW_PV_STORAGE_KEY, "1");

      // Update previous pathname to current one
      previousPathname.current = pathname;
    }
  }, [pathname]);
}

/**
 * Do we need to tell tagular we have a new page view?
 */
export function needsNewPageView() {
  const set = sessionStorage.getItem(NEED_NEW_PV_STORAGE_KEY);
  if (set) {
    sessionStorage.removeItem(NEED_NEW_PV_STORAGE_KEY);
    return true;
  }
  return false;
}
